<template>
  <div class="service">
    <div class="headerFixed">
      <!-- 顶部Header -->
      <div class="address_div">
        <div class="left-box">
          <svg-icon icon-class="location" class="location"></svg-icon>
          <span class="mapAddressText" @click="jumpAddress">{{ mapAddress }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="right-box">
          <img src="@/assets/img/search.svg" alt="" class="search-icon" @click="jumpSearch">
          <div class="switchShow">
            <span class="switchShowText" :class="showCurIndex == index ? 'switchShowTextActive' : ''"
              @click="changeShowType(index)" v-for="(item, index) in showType" :key="index">{{ item }}</span>
          </div>
        </div>
      </div>

      <!-- 顶部筛选下拉框 -->
      <van-dropdown-menu>
        <van-dropdown-item :title="distanceText" ref="distance">
          <van-cell center v-for="(item, index) in distanceCondition" :key="index">
            <template>
              <div class="cell-item" @click="changeCondition(index, 'distance')">
                <img src="@/assets/img/choose-active.svg" alt="" class="icon" v-if="distanceIndex == index">
                <span class="text">{{ item.name }}</span>
              </div>
            </template>
          </van-cell>
        </van-dropdown-item>
        <van-dropdown-item :title="sortText" ref="sort">
          <van-cell center v-for="(item, index) in sortCondition" :key="index">
            <template>
              <div class="cell-item" @click="changeCondition(index, 'sort')">
                <img src="@/assets/img/choose-active.svg" alt="" class="icon" v-if="sortIndex == index">
                <span class="text">{{ item.name }}</span>
              </div>
            </template>
          </van-cell>
        </van-dropdown-item>
        <van-dropdown-item title="筛选" ref="other">
          <van-cell center>
            <template>
              <div class="label">服务状态筛选</div>
              <div class="btn-box">
                <span v-for="(item, index) in ['空闲', '忙碌',]" :key="index" class="btn-item"
                  :class="index == statusIndex ? 'btn-item-active' : ''" @click="statusIndex = index">{{ item }}</span>
              </div>
            </template>
          </van-cell>
          <van-cell center>
            <template>
              <div class="label">性别筛选</div>
              <div class="btn-box">
                <span v-for="(item, index) in [ '女','男']" :key="index" class="btn-item"
                  :class="index == sexIndex ? 'btn-item-active' : ''" @click="sexIndex = index">{{ item }}</span>
              </div>
            </template>
          </van-cell>
          <van-cell center>
            <template>
              <div class="label">年龄筛选</div>
              <div class="btn-box">
                <span v-for="(item, index) in ageCondition" :key="index" class="btn-item"
                  :class="index == ageIndex ? 'btn-item-active' : ''" @click="changeCondition(index, 'age')">{{
                  item.name }}</span>
              </div>
            </template>
          </van-cell>
          <van-cell center>
            <div class="submit-btn">
              <div class="btn reset" @click="reset">重置</div>
              <div class="sizedbBox"></div>
              <div class="btn submit" @click="submit">确定</div>
            </div>
          </van-cell>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <!-- 筛选列表 -->
    <div class="selectAttendant" v-if="showCurIndex == 0">
      <template v-if="dataList.length > 0">
        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="-- 没有更多了 --" @load="onLoad" :immediate-check="false">
          <TechnicianList :dataList="dataList" @chooseAttentdant="chooseAttentdant" @distanceAttendant="distanceAttendant"/>
        </van-list>
        <!-- </van-pull-refresh> -->

      </template>
      <template v-else>
        <div class="nullDataDiv">
          <img src="@/assets/img/error.png" alt="">
          <span class="text">暂无数据</span>
        </div>
      </template>
    </div>

    <!-- 地图展示 -->

    <div class="amapBox" v-else>
      <Map :imgList="dataList"/>
    </div>

    <footBar :pageName="1" />
  </div>
</template>

<script>
import footBar from "@/components/footBar"
import { getToDay } from "@/utils/index"
import AMapLoader from '@amap/amap-jsapi-loader';
import Location from '@/utils/location'
import { screenArtificer, getMapArtificerList } from '@/api/service'
import TechnicianList from '@/components/technicianList'
import Map from '@/components/map'
import wx from "weixin-js-sdk"

export default {
  data() {
    return {
      mapAddress: "获取中...",
      lng: 116.397455,
      lat: 39.919087,
      showCurIndex: 0,
      showType: ['列表', '地图'],
      distanceText: '距离',
      sortText: '排序',
      distanceCondition: [{ name: '不限', value: '' }, { name: '1KM', value: 1 }, { name: '2KM', value: 2 }, { name: '3KM', value: 3 }, { name: '4KM', value: 4 }, { name: '5KM', value: 5 }, { name: '10KM', value: 10 }, { name: '20KM', value: 20 }],
      sortCondition: [{ name: '不限', value: '' }, { name: '距离最近', value: 'CLOSEST' }, { name: '人气最高', value: 'MOST_POPULAR' }, { name: '接单最多', value: 'MOST_ORDERS' }, { name: '好评最多', value: 'MOST_PRAISED' }],
      ageCondition: [{ name: '00后', value: 'AFTER_00' }, { name: '80后', value: 'AFTER_80' }, { name: '90后', value: 'AFTER_90' },],
      distanceIndex: 0,
      sortIndex: 0,
      statusIndex: -1,
      sexIndex: -1,
      ageIndex: -1,
      page: 1,
      pageSize:10,
      total: 0,
      loading: false,
      finished: false,
      refreshing: false,          // 控制下拉刷新
      dataList: [],
      mapImgList:[
            // {
            //     artificerAvatar:'https://img1.baidu.com/it/u=2178428741,2611935281&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500',
            //     longitude:'116.473083',
            //     latitude:'39.993252'
            // },
            // {
            //     artificerAvatar:'https://img2.baidu.com/it/u=424474220,2326426386&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500',
            //     longitude:'116.495027',
            //     latitude:'39.983285'
            // },
            // {
            //     artificerAvatar:'https://img0.baidu.com/it/u=2480702846,2185603543&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500',
            //     longitude:'116.452962',
            //     latitude:'39.934474'
            // },
            // {
            //     artificerAvatar:'https://img1.baidu.com/it/u=3256652730,2284715427&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=800',
            //     longitude:'116.419323',
            //     latitude:'40.062409'
            // },
        ],

      distance: "",                // 距离
      sort: "",                    // 排序方式
      age: "",                     // 年龄
    }
  },
  components: {
    footBar, TechnicianList, Map
  },
  created() {
    const selectCityAddress = JSON.parse(sessionStorage.getItem('selectCityAddress'))
    this.dataList = []
    if (selectCityAddress) {
        // this.mapAddress = (selectCityAddress.cityInfo.detailAddress.split(' '))[0]
        this.mapAddress = selectCityAddress.cityInfo.detailAddress
        this.lat = selectCityAddress.cityInfo.lat
        this.lng = selectCityAddress.cityInfo.lng
        this.getData()
    }else{
      this.setMap()
    }
  },
  activated(){

  },
  deactivated(){
    // this.page = 1
  },
  methods: {
    distanceAttendant(item){
      this.$router.push({path:'/serverMap', query: {obj:JSON.stringify(item)}})
    },
    changeShowType(index){
      this.showCurIndex = index
      this.dataList = []
      this.getData()
    },  
    jumpAddress() {
      let _this = this
      if(this.mapAddress == "定位获取失败"){
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          _this.$store.commit("loadingControl",true)
          wx.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
              _this.setMap();
            },
            fail:function(err){
              _this.$toast("定位失败，请检查网络或GPS是否正常开启")
            },
            complete:()=>{
              _this.$store.commit("loadingControl",false)
            }
          });
        } else {
          this.mapAddress = "定位中..."
          this.setMap();
        }
      }else if(this.getwxcode("/selectAddress") && this.mapAddress != "定位中..."){
        this.$router.push({
          path: "/selectAddress",
        })
      }
    },
    jumpSearch() {
      this.$router.push({path:'/searchAttendant', query:{lat:this.lat, lng:this.lng}})  
    },
    getData() {
      // 异步更新数据
      let params = {
        pageIndex: this.page,
        pageSize: this.pageSize,
        lng: this.lng,
        lat: this.lat,
        distance: this.distance,
        sort: this.sort,
        serviceStatus: this.statusIndex == -1 ? "" : this.statusIndex,
        sex: this.sexIndex == -1 ? "" : this.sexIndex,
        age: this.age == -1 ? "" : this.age,
      }
      if(this.showCurIndex == 1){
        params.pageSize = 1000
      }else{
        params.pageSize = 10
      }

      screenArtificer(params).then(res => {
        if (res.code == 10000) {
          this.dataList = this.dataList.concat(res.data.records)
          this.total = res.data.total

          this.loading = false;
          if (this.dataList.length >= this.total) {
            this.finished = true;
          }
        }
      }).catch(err => {
        console.log(err)
      })
        // getMapArtificerList(params).then(res => {
        //    if (res.code == 10000) {
        //       this.mapImgList = this.mapImgList.concat(res.data.records)
        //     }
        // }).catch(err => {
        //     console.log(err)
        //   })
    },
    // 获取定位
    setMap() {
      let _this = this
      AMapLoader.load({
        plugins: ['AMap.Geolocation'],
      }).then((AMap) => {
        Location.getCurrentLocation(AMap, (result) => {
          _this.mapAddress = result.pois[0].name || result.addressComponent.building
          _this.lng = result.position.lng
          _this.lat = result.position.lat
          this.getData()
        }, () => {
          this.mapAddress = "定位获取失败"
          this.$toast("定位失败，请检查网络或GPS是否正常开启")
          this.getData()
        })
      }).catch(e => {
        console.log(e);
      });
    },
    // 更改筛选条件
    changeCondition(index, type) {
      // 充值数据
      this.dataList = []
      this.page = 1
      this.finished = false
      this.loading = true
      if (type == "distance") {
        this.distanceIndex = index
        this.$refs.distance.toggle(false);
        this.distanceText = this.distanceCondition[index].name
        this.distance = this.distanceCondition[index].value
        this.getData()
      } else if (type == "sort") {
        this.sortIndex = index
        this.$refs.sort.toggle(false);
        this.sortText = this.sortCondition[index].name
        this.sort = this.sortCondition[index].value
        this.getData()
      } else if (type == "age") {
        this.ageIndex = index
        this.age = this.ageCondition[index].value
      }

    },
    chooseAttentdant(item) {
      console.log(item)
    },
    getToDay(str) {
      return getToDay(str)
    },
    reset() {
      this.statusIndex = -1
      this.sexIndex = -1
      this.ageIndex = -1
      this.age = ""
    },
    submit() {
      // 充值数据
      this.dataList = []
      this.page = 1
      this.finished = false
      this.loading = true

      this.$refs.other.toggle(false);
      this.getData()
    },
    // 上拉加载
    onLoad() {
      if (this.page < Math.floor((this.total + 9) / 10)) {
        this.page++
        this.getData()
      } else {
        this.finished = true
      }
      //  if( this.dataList.length < this.total){
      // this.page++
      // this.getData()

      // }else{
      //   this.finished = true;
      // }
    },
    // 下拉刷新
    //  async onRefresh() {
    //   this.page = 1
    //   await this.getData()
    //   this.refreshing = false;
    // },
  }
}
</script>

<style lang="less" scoped>
.service {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);

  .headerFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
  }

  .selectAttendant {
    // flex: 1;
    // overflow-y: auto;
  }
}




.address_div {
  padding: .4rem .2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .location {
    width: .36rem;
    height: .36rem;
  }

  .left-box{
    display: flex;
    align-items: center;
  }

  .mapAddressText {
    display: inline-block;
    // width: 3rem;
    max-width: 3rem;
    margin: 0 0.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .right-box {
    display: flex;
    align-items: center;

    .search-icon {
      width: .7rem;
      height: .7rem;
    }

    .switchShow {
      background-color: #EBEBEB;
      border-radius: .15rem;
      padding: .15rem .1rem;
      margin-left: .15rem;

      .switchShowText {
        padding: .1rem .2rem;
        border-radius: .15rem;
        font-size: .32rem;
      }

      .switchShowTextActive {
        background-color: #fff;
      }
    }
  }
}

.cell-item {
  display: flex;
  align-items: center;

  .icon {
    width: .5rem;
    height: .5rem;
    margin-right: .15rem;
  }
}

.btn-box {
  margin-top: .15rem;

  .btn-item {
    display: inline-block;
    padding: 0rem .5rem;
    text-align: center;
    background-color: #F5F5F5;
    border-radius: .8rem;
    margin-right: .2rem;
    font-size: .35rem;
  }

  .btn-item-active {
    background-color: #E82B2F;
    color: white;
  }
}

.submit-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sizedbBox {
    width: .5rem;
  }

  .btn {
    flex: 1;
    text-align: center;
    padding: .3rem 0;
    border-radius: 2rem;
  }

  .reset {
    background-color: #F5F5F5;
    color: black;
  }

  .submit {
    background-color: #E82B2F;
    color: white;
  }
}

// 列表容器
.selectAttendant {
  min-height: calc(100vh - 2.65rem);
  margin-top: 2.65rem;
  display: flex;
  flex-direction: column;
  background: rgba(100, 101, 102, 0.1);
  padding: 0.4rem;
  padding-bottom: 1.8rem;
  box-sizing: border-box;

  .content-li {
    display: flex;
    background: white;
    border-radius: .2rem;
    padding: .3rem;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    .userImg {
      width: 2rem;
      height: 2rem;
      margin-right: 0.4rem;
    }

    .userInfo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .plane {
        color: #1296db;
      }
    }

    .time-show-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    .time-show {
      display: flex;
      flex-direction: column;
      font-size: .28rem;
      color: #EB4E52;
      border: solid 1px #F9CACA;
      border-radius: 3px;

      span {
        padding: 2px 5px;

        &:first-child {
          background: #FBDDDF;
        }
      }
    }

    .drop {
      display: flex;
      flex-direction: column;
      width: .5rem;
      align-items: center;

      span {
        width: 3px;
        height: 3px;
        background: #000;
        border-radius: 50%;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }

    .popover-div {
      position: absolute;
      right: 0.4rem;
      bottom: 0.2rem;
      background: rgb(228, 223, 223);
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.18rem .2rem;
      border-radius: .1rem;
      box-shadow: 1px 1px 1px 1px rgb(228, 223, 223);

      .unlikeIcon {
        font-size: .4rem;
        margin-right: 0.1rem;
      }

    }
  }
}

// 地图容器
.amapBox{
  height:calc(100vh - 4rem);
  margin-top: 2.65rem;
  background: rgba(100, 101, 102, 0.1);
  box-sizing: border-box;
}
</style>